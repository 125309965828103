exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-component-class-introduce-tsx": () => import("./../../../src/pages/component/classIntroduce.tsx" /* webpackChunkName: "component---src-pages-component-class-introduce-tsx" */),
  "component---src-pages-component-contact-tsx": () => import("./../../../src/pages/component/contact.tsx" /* webpackChunkName: "component---src-pages-component-contact-tsx" */),
  "component---src-pages-component-gallery-tsx": () => import("./../../../src/pages/component/gallery.tsx" /* webpackChunkName: "component---src-pages-component-gallery-tsx" */),
  "component---src-pages-component-gallerybak-tsx": () => import("./../../../src/pages/component/gallerybak.tsx" /* webpackChunkName: "component---src-pages-component-gallerybak-tsx" */),
  "component---src-pages-component-gender-tsx": () => import("./../../../src/pages/component/gender.tsx" /* webpackChunkName: "component---src-pages-component-gender-tsx" */),
  "component---src-pages-component-header-tsx": () => import("./../../../src/pages/component/header.tsx" /* webpackChunkName: "component---src-pages-component-header-tsx" */),
  "component---src-pages-component-layout-tsx": () => import("./../../../src/pages/component/layout.tsx" /* webpackChunkName: "component---src-pages-component-layout-tsx" */),
  "component---src-pages-component-meals-tsx": () => import("./../../../src/pages/component/meals.tsx" /* webpackChunkName: "component---src-pages-component-meals-tsx" */),
  "component---src-pages-component-news-tsx": () => import("./../../../src/pages/component/news.tsx" /* webpackChunkName: "component---src-pages-component-news-tsx" */),
  "component---src-pages-component-org-formation-tsx": () => import("./../../../src/pages/component/org_formation.tsx" /* webpackChunkName: "component---src-pages-component-org-formation-tsx" */),
  "component---src-pages-component-photo-pattern-tsx": () => import("./../../../src/pages/component/photoPattern.tsx" /* webpackChunkName: "component---src-pages-component-photo-pattern-tsx" */),
  "component---src-pages-component-photo-tsx": () => import("./../../../src/pages/component/photo.tsx" /* webpackChunkName: "component---src-pages-component-photo-tsx" */),
  "component---src-pages-component-photolist-tsx": () => import("./../../../src/pages/component/photolist.tsx" /* webpackChunkName: "component---src-pages-component-photolist-tsx" */),
  "component---src-pages-component-stepview-tsx": () => import("./../../../src/pages/component/stepview.tsx" /* webpackChunkName: "component---src-pages-component-stepview-tsx" */),
  "component---src-pages-component-swiper-modal-tsx": () => import("./../../../src/pages/component/swiperModal.tsx" /* webpackChunkName: "component---src-pages-component-swiper-modal-tsx" */),
  "component---src-pages-component-swiperview-tsx": () => import("./../../../src/pages/component/swiperview.tsx" /* webpackChunkName: "component---src-pages-component-swiperview-tsx" */),
  "component---src-pages-component-teacher-tsx": () => import("./../../../src/pages/component/teacher.tsx" /* webpackChunkName: "component---src-pages-component-teacher-tsx" */),
  "component---src-pages-component-teams-tsx": () => import("./../../../src/pages/component/teams.tsx" /* webpackChunkName: "component---src-pages-component-teams-tsx" */),
  "component---src-pages-component-welcome-tsx": () => import("./../../../src/pages/component/welcome.tsx" /* webpackChunkName: "component---src-pages-component-welcome-tsx" */),
  "component---src-pages-component-whats-news-tsx": () => import("./../../../src/pages/component/whatsNews.tsx" /* webpackChunkName: "component---src-pages-component-whats-news-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meals-index-tsx": () => import("./../../../src/pages/meals/index.tsx" /* webpackChunkName: "component---src-pages-meals-index-tsx" */),
  "component---src-pages-news-[id]-tsx": () => import("./../../../src/pages/news/[id].tsx" /* webpackChunkName: "component---src-pages-news-[id]-tsx" */),
  "component---src-pages-photo-[id]-tsx": () => import("./../../../src/pages/photo/[id].tsx" /* webpackChunkName: "component---src-pages-photo-[id]-tsx" */),
  "component---src-pages-view-about-tsx": () => import("./../../../src/pages/view/about.tsx" /* webpackChunkName: "component---src-pages-view-about-tsx" */),
  "component---src-pages-view-activity-tsx": () => import("./../../../src/pages/view/activity.tsx" /* webpackChunkName: "component---src-pages-view-activity-tsx" */),
  "component---src-pages-view-budgetary-tsx": () => import("./../../../src/pages/view/budgetary.tsx" /* webpackChunkName: "component---src-pages-view-budgetary-tsx" */),
  "component---src-pages-view-calendar-tsx": () => import("./../../../src/pages/view/calendar.tsx" /* webpackChunkName: "component---src-pages-view-calendar-tsx" */),
  "component---src-pages-view-campus-safety-tsx": () => import("./../../../src/pages/view/campusSafety.tsx" /* webpackChunkName: "component---src-pages-view-campus-safety-tsx" */),
  "component---src-pages-view-class-1-tsx": () => import("./../../../src/pages/view/class1.tsx" /* webpackChunkName: "component---src-pages-view-class-1-tsx" */),
  "component---src-pages-view-class-2-tsx": () => import("./../../../src/pages/view/class2.tsx" /* webpackChunkName: "component---src-pages-view-class-2-tsx" */),
  "component---src-pages-view-class-formation-tsx": () => import("./../../../src/pages/view/class_formation.tsx" /* webpackChunkName: "component---src-pages-view-class-formation-tsx" */),
  "component---src-pages-view-class-introduce-tsx": () => import("./../../../src/pages/view/classIntroduce.tsx" /* webpackChunkName: "component---src-pages-view-class-introduce-tsx" */),
  "component---src-pages-view-collaboration-tsx": () => import("./../../../src/pages/view/collaboration.tsx" /* webpackChunkName: "component---src-pages-view-collaboration-tsx" */),
  "component---src-pages-view-course-1-tsx": () => import("./../../../src/pages/view/course1.tsx" /* webpackChunkName: "component---src-pages-view-course-1-tsx" */),
  "component---src-pages-view-course-2-tsx": () => import("./../../../src/pages/view/course2.tsx" /* webpackChunkName: "component---src-pages-view-course-2-tsx" */),
  "component---src-pages-view-course-3-tsx": () => import("./../../../src/pages/view/course3.tsx" /* webpackChunkName: "component---src-pages-view-course-3-tsx" */),
  "component---src-pages-view-course-4-tsx": () => import("./../../../src/pages/view/course4.tsx" /* webpackChunkName: "component---src-pages-view-course-4-tsx" */),
  "component---src-pages-view-course-5-tsx": () => import("./../../../src/pages/view/course5.tsx" /* webpackChunkName: "component---src-pages-view-course-5-tsx" */),
  "component---src-pages-view-edu-tsx": () => import("./../../../src/pages/view/edu.tsx" /* webpackChunkName: "component---src-pages-view-edu-tsx" */),
  "component---src-pages-view-empowerment-tsx": () => import("./../../../src/pages/view/empowerment.tsx" /* webpackChunkName: "component---src-pages-view-empowerment-tsx" */),
  "component---src-pages-view-epidemic-prevention-tsx": () => import("./../../../src/pages/view/epidemicPrevention.tsx" /* webpackChunkName: "component---src-pages-view-epidemic-prevention-tsx" */),
  "component---src-pages-view-fee-tsx": () => import("./../../../src/pages/view/fee.tsx" /* webpackChunkName: "component---src-pages-view-fee-tsx" */),
  "component---src-pages-view-friend-tsx": () => import("./../../../src/pages/view/friend.tsx" /* webpackChunkName: "component---src-pages-view-friend-tsx" */),
  "component---src-pages-view-game-tsx": () => import("./../../../src/pages/view/game.tsx" /* webpackChunkName: "component---src-pages-view-game-tsx" */),
  "component---src-pages-view-gender-tsx": () => import("./../../../src/pages/view/gender.tsx" /* webpackChunkName: "component---src-pages-view-gender-tsx" */),
  "component---src-pages-view-glory-tsx": () => import("./../../../src/pages/view/glory.tsx" /* webpackChunkName: "component---src-pages-view-glory-tsx" */),
  "component---src-pages-view-home-learning-tsx": () => import("./../../../src/pages/view/homeLearning.tsx" /* webpackChunkName: "component---src-pages-view-home-learning-tsx" */),
  "component---src-pages-view-home-tsx": () => import("./../../../src/pages/view/home.tsx" /* webpackChunkName: "component---src-pages-view-home-tsx" */),
  "component---src-pages-view-impressions-taipei-tsx": () => import("./../../../src/pages/view/impressionsTaipei.tsx" /* webpackChunkName: "component---src-pages-view-impressions-taipei-tsx" */),
  "component---src-pages-view-info-tsx": () => import("./../../../src/pages/view/info.tsx" /* webpackChunkName: "component---src-pages-view-info-tsx" */),
  "component---src-pages-view-internal-control-tsx": () => import("./../../../src/pages/view/internalControl.tsx" /* webpackChunkName: "component---src-pages-view-internal-control-tsx" */),
  "component---src-pages-view-meals-tsx": () => import("./../../../src/pages/view/meals.tsx" /* webpackChunkName: "component---src-pages-view-meals-tsx" */),
  "component---src-pages-view-new-students-tsx": () => import("./../../../src/pages/view/newStudents.tsx" /* webpackChunkName: "component---src-pages-view-new-students-tsx" */),
  "component---src-pages-view-news-detail-tsx": () => import("./../../../src/pages/view/news_detail.tsx" /* webpackChunkName: "component---src-pages-view-news-detail-tsx" */),
  "component---src-pages-view-news-tsx": () => import("./../../../src/pages/view/news.tsx" /* webpackChunkName: "component---src-pages-view-news-tsx" */),
  "component---src-pages-view-organizational-tsx": () => import("./../../../src/pages/view/organizational.tsx" /* webpackChunkName: "component---src-pages-view-organizational-tsx" */),
  "component---src-pages-view-parent-child-tsx": () => import("./../../../src/pages/view/parentChild.tsx" /* webpackChunkName: "component---src-pages-view-parent-child-tsx" */),
  "component---src-pages-view-photo-detail-tsx": () => import("./../../../src/pages/view/photo_detail.tsx" /* webpackChunkName: "component---src-pages-view-photo-detail-tsx" */),
  "component---src-pages-view-photo-tsx": () => import("./../../../src/pages/view/photo.tsx" /* webpackChunkName: "component---src-pages-view-photo-tsx" */),
  "component---src-pages-view-policy-promotion-tsx": () => import("./../../../src/pages/view/policyPromotion.tsx" /* webpackChunkName: "component---src-pages-view-policy-promotion-tsx" */),
  "component---src-pages-view-principal-tsx": () => import("./../../../src/pages/view/principal.tsx" /* webpackChunkName: "component---src-pages-view-principal-tsx" */),
  "component---src-pages-view-principal-words-tsx": () => import("./../../../src/pages/view/principal_words.tsx" /* webpackChunkName: "component---src-pages-view-principal-words-tsx" */),
  "component---src-pages-view-responsibilities-tsx": () => import("./../../../src/pages/view/responsibilities.tsx" /* webpackChunkName: "component---src-pages-view-responsibilities-tsx" */),
  "component---src-pages-view-school-song-tsx": () => import("./../../../src/pages/view/school_song.tsx" /* webpackChunkName: "component---src-pages-view-school-song-tsx" */),
  "component---src-pages-view-school-vision-tsx": () => import("./../../../src/pages/view/school_vision.tsx" /* webpackChunkName: "component---src-pages-view-school-vision-tsx" */),
  "component---src-pages-view-sitemap-tsx": () => import("./../../../src/pages/view/sitemap.tsx" /* webpackChunkName: "component---src-pages-view-sitemap-tsx" */),
  "component---src-pages-view-taipei-tsx": () => import("./../../../src/pages/view/taipei.tsx" /* webpackChunkName: "component---src-pages-view-taipei-tsx" */),
  "component---src-pages-view-traffic-tsx": () => import("./../../../src/pages/view/traffic.tsx" /* webpackChunkName: "component---src-pages-view-traffic-tsx" */),
  "component---src-pages-view-video-tsx": () => import("./../../../src/pages/view/video.tsx" /* webpackChunkName: "component---src-pages-view-video-tsx" */)
}

